.dndloginpage {
  background-image: url("../../assets/loginimage/D&Dbkfinal.png");
  background-size: cover !important;
  background-position: center !important;
  overflow: hidden !important;
  height: 100vh;
  width: 100%;
  margin: 0px;
  align-items: center !important;
  place-content: center !important;
  display: flex !important;
  justify-content: center !important;
}
.loginpage {
  background-color: aliceblue;
  /* place-items: center; */
  position: relative !important;
  align-items: center;
  width: 80%;
  top: 9%;
  box-shadow: #666;
}
.logcol2 img {
  /* background-image: url("../../assets/loginimage/D&D.png"); */
  /* background-size: cover !important; */
  height: 78vh !important;
  object-fit: cover;
  width: 100%;
  overflow: hidden !important;
}
.logcol2{
    padding: 0;
}
.logcol1 {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  padding-right: 0px;
  background-color: white;
}
.logocontain {
  text-align: -webkit-center;
  margin-top: 15%;
  margin-bottom: 10%;
  background-color: #ffffff;
}

.logforminput {
  width: 100%;
  font-size: 14px;
  left: 10px;
  height: 30%;
  border-radius: 5px;
  border: 0.9px solid gray;
  margin-top: 3%;
  margin-bottom: 2%;
}
.logpageform {
  display: grid;
  width: 50%;
  row-gap: 5%;
}
.loglabel {
  text-align: left !important;
}
.loginpageheadtext {
  z-index: 1;
  position: absolute;
  place-content: center;
  top: 5% !important;
  padding: 0% 3% 0% 5%;
}
.textloinghead,
.textloinghead1 {
  font-size: 100px;
  font-weight: 500;
}
.textloinghead {
  color: #ffffff;
}
.textloinghead1 {
  color: #01c2f9;
}
.dndrowcontainer {
  box-shadow: 14px 14px 18px 5px rgba(0, 0, 0, 0.5) !important;
}

.logbtn {
  border-radius: 8px;
  background-color: #01c2f9;
  outline: none;
  border: 0px;
  color: white !important;
  text-align: center;
  height: 40px !important;
  width: 100% !important;
  align-items: center;
}
.submitbtn {
  color: #ffffff !important;
  outline: none !important;
  border: none !important;
  width: 100% !important;

  font-weight: 700 !important;
}
.buttonlog {
  text-align: center !important;
}
.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-label {
  font-size: 14px;
  color: #666;
  display: block;
  margin-bottom: 5px;
}

.eye-icon {
  position: absolute;
  right: 10%;
  top: 28px;
  transform: translateY(-50%);
  cursor: pointer;
}
.inuptfield-icon {
  align-items: center;
}
.field-wrapper {
  position: relative;
  margin-bottom: 15px;
}

.field-wrapper input {
  border: 1px solid #dadce0;
  padding: 15px 32px;
  border-radius: 4px;
  width: 100%;
}

.field-wrapper input:focus {
  border: 1px solid #1a73e8;
}

.field-placeholder {
  font-size: 16px;
  position: absolute;
  bottom: 17px;
  box-sizing: border-box;
  color: #80868b;
  left: 8px;
  padding: 0 8px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  text-align: left;
  width: 100%;
}

.field-placeholder span {
  background: #ffffff;
  padding: 0px 8px;
}

.field-wrapper input:not([disabled]):focus ~ .field-placeholder {
  color: #1a73e8;
}

.field-wrapper input:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
  transform: scale(0.75) translateY(-39px) translateX(-60px);
}

.inputfield-icon {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .logcol2 {
    display: none;
  }
  .logcol2 {
    height: 100vh !important;
  }
  .logpageform {
    width: 90%;
  }
  .logocontain {
    text-align: -webkit-center !important;
  }
  .dndloginpage{
    overflow: hidden !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 998px) {
  .logpageform {
    width: 90%;
  }
  .loginpage {
    top: 12%;
  }
}
