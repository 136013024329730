body {
  background-color: rgb(218 231 231);
}
.sitedetailsbtn {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.dashboard {
  background-color: rgb(218 231 231);

  margin-bottom: 40px !important;
}
.custom-ckeditor .ck-editor__editable {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-ckeditor:focus .ck-editor__editable:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.btnheight {
  width: 35%;
}
.rs-btn-close .rs-icon {
  vertical-align: bottom;
  height: 20px;
  width: 30px;
}
.excelbtn {
  background: linear-gradient(180deg, #30c9ed, rgb(0 120 153)) !important;
  font-weight: 700;
  width: auto;
  margin-right: 10px !important;
  color: #fff;
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.5);
  border-radius: 8px !important;
  border: 1px solid #00a7f6 !important;
}
.iconbg {
  background: none !important;
}
.excelbtn:hover {
  /* margin-right: 10px !important; */
  /* border: 1px solid #00a7f6 !important; */
  background: #ffffff !important;
  color: #00a7f6 !important;
  border-color: #00a7f6 !important;
}
.rs-pagination-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.excelbtntable {
  background-color: #d8d8d8 !important;
  font-weight: 500;
  width: 10%;

  color: #6e6f6f;
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.5);
  border-radius: 8px !important;
  border: none !important;
}
.excelbtntable:hover {
  margin-right: 0px !important;
  background-color: #ffffff !important;
  color: #6e6f6f !important;
  border-color: #6e6f6f !important;
}

.save-btn {
  background-color: #00a7f6 !important;
  font-weight: 500;
  width: 10%;
  color: #fff;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 70px 0px;
}

.bar-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.chart-info {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.05px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-info div {
  width: 22%;
}

.overview-card-body {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.card-title {
  font-size: 14.06px;
  font-weight: 800;
  line-height: 19.18px;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(32, 34, 36, 0.7);
}

.card-info {
  font-size: 24.6px;
  font-weight: 700;
  line-height: 33.56px;
  letter-spacing: 0.8px;
  text-align: left;
  color: rgba(32, 34, 36, 1);
  margin-bottom: 0px;
  margin-top: 4%;
}

.card-details {
  height: 50px;
}

.card-section {
  width: 23%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 10px 12px 14px;
  height: 145px;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}

.card-section h2 {
  margin-top: 0;
}
.card-section span {
  font-size: 16px;
}
.leads-icon {
  position: absolute;
  top: 30%;
  right: 15px;
  transform: translateY(-50%);
  width: 50px !important;
  height: auto;
}
.percentage-container {
  display: flex;
  align-items: center;
  color: rgba(32, 34, 36, 0.7);
  font-size: 14px;
  font-weight: 500;
}

.percentage {
  color: rgba(0, 182, 155, 1);
  margin-left: 1.5%;
  margin-right: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0px 70px;
}
.negative {
  color: red;
}
.card-chart {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  position: relative;
  margin-right: 20px;
  margin-bottom: 32px;
  box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
  height: 60%;
}
.card-chart p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.7px;
  color: rgba(172, 172, 172, 1);
  text-align: left;
  margin-bottom: 0px;
}
.select-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card-chart2 {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  height: 57%;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}
.datesearch {
  width: 20%;
  margin: 0px;
}

/* .container-table {
  box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0px 70px 20px;
  position: relative;
}

.table {
  --bs-table-border-color: #fff;
  --bs-table-bg: rgb(251, 255, 255);
}

.table th,
td {
  text-align: center;
}

.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
}

thead tr {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.82px;
  letter-spacing: 0.4px;
  color: #595959;
  background-color: #dedede;
}

.table td {
  font-size: 16px;
  font-weight: 500;
  line-height: 24.55px;
  letter-spacing: 0.4px;
  color: rgba(96, 96, 96, 1);
}

.table-header {
  padding: 20px 20px;
}

.table-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: rgb(230, 251, 253, 1);
  --bs-table-bg-type: #fff;
}
.rs-table-cell-header .rs-table-cell-content {
  background-color: #595959 !important;
  color: white;
}
.rs-table-cell-header-icon-sort {
  color: white;
}
.rs-table {
  border-radius: 10px;
}
.pagination-and-info-user {
  margin: 0;
}

.container-table p {
  font-size: 22px;
  font-weight: 600;
  line-height: 30.01px;
  letter-spacing: 0.4px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  width: 22%;
  margin-bottom: 0px !important;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  margin: 10px;
  height: 40px;
  width: 15%;
}

select {
  border: none;
  background-color: rgba(249, 251, 255, 1);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  letter-spacing: 0.4px;
  color: rgba(126, 126, 126, 1);
} */

.search-field1 {
  width: 25%;
  margin-right: 3%;
}

.table-search {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
}

.search-input {
  padding: 10px 30px 10px 40px;
  background-image: url("../../../assets/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  background-color: #6b6e6e2b;
  box-shadow: 0px 10.53px 63.16px 0px rgba(178, 255, 211, 0.13);
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.37px;
  letter-spacing: 0.4px;
  color: rgb(63, 63, 63);
}

.bold-text {
  font-weight: bold;
}

.product-info {
  display: flex;
  align-items: flex-start;
}

.product-details {
  margin-left: 10px;
  text-align: left;
}

.product-image {
  width: 50px;
  height: 50px;
}
.product-details span {
  display: block;
  text-align: left;
}
.product-name-column {
  width: 500px;
}

.error-message-container {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 10%;
}
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pagination-controls button {
  padding: 5px 10px;
  margin: 0 2px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}
.tabelselect {
  width: 200px !important;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls button.active {
  background-color: #d0eaee;
}

.pagination-controls select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.mb-10 {
  margin-bottom: 10px;
}
.datesearchinput {
  box-shadow: 0 0 5px 1px #0c0a0b40;
  border: none;
  height: 50px;
  width: 200px !important;
  border-radius: 15px;
  padding-left: 15px;
  outline: none;
  margin-left: 2px;
  margin-right: 2px;
}
.datesearch1 {
  margin-left: 5px;
  width: 15%;
  margin-bottom: 0px !important;
}
.date_container {
  display: flex;
  align-items: center;
}

.excelbtn1 {
  width: 50%;
}
.mandatoryfield {
  color: red !important;
}
.tabletitle {
  font-size: 24px;
  font-weight: 700;
  color: #646464;
  cursor: default;
  letter-spacing: 0.035em;
  /* padding: 12px 25px !important; */
  pointer-events: none;
}

.new-vendorlist-card {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.new-vendorlist-row {
  margin-bottom: 15px;
}

.new-vendorlist-col {
  display: flex;
  flex-direction: column;
}

.vendor-fields {
  margin-bottom: 15px;
  display: "flow";
}

.vendor-fields1 {
  margin-bottom: 15px;
  display: flex;
}

.field-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.field-value {
  font-size: 14px;
}

/* General styles */
.vendor-fields1,
.vendor-fields {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.field-label {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
}
:root {
  --ck-color-selector-caption-text: #ffffff !important;
}

.field-value {
  flex: 1;
  white-space: normal;
  word-break: break-all !important;
}
.vendorlistcard {
  margin-top: 0px !important;
  margin: 1%;
}
.grpdatepicker {
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  gap: 10px !important;
}
.expencebtn-excel {
  width: auto;
}
.expencebtn-search {
  width: 100% !important;
}
.container-lt {
  width: 98%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
/* Mobile view adjustments */
@media (max-width: 767px) {
  .new-vendorlist-row {
    display: flex;
    flex-direction: column;
  }

  .new-vendorlist-col {
    margin-bottom: 15px;
  }

  .vendor-fields1,
  .vendor-fields {
    flex-direction: column;
  }

  .field-label {
    margin-bottom: 5px;
  }
}

/* Desktop view adjustments */
@media (min-width: 768px) {
  .new-vendorlist-row {
    display: flex;
    flex-wrap: wrap;
  }

  .new-vendorlist-col {
    flex: 0 0 33.33%; /* 3 columns */
    max-width: 33.33%; /* 3 columns */
    box-sizing: border-box;
    padding: 0 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .card-section {
    width: calc(100% - 20px);
    margin-bottom: 15px;
  }
  .sitedetailsbtn {
    flex-direction: column !important;
  }
  .sitedetailsbtn1 {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .excelbtn1 {
    width: 100%;
  }
  .expencebtn-excel {
    width: 40% !important;
  }

  .card-chart {
    flex: 100%;
    padding-right: 0;
  }

  .card-chart2 {
    flex: 100%;
    padding: 0;
  }

  .leads-icon {
    width: 40px;
  }
  .datesearch {
    width: 100%;
    margin: 0px;
  }
  .datesearchinput {
    width: 100%;
  }
  .transportbtn {
    width: 40% !important;
  }
  .searchfieldscontain {
    display: flex;
    width: 80%;
    align-items: center;
    margin-top: 20px;
  }
  .btnmobiledash {
    margin-top: 0px !important;
  }
  .vendorsearch {
    align-items: center !important;
    display: flex !important;
    width: 105% !important;
  }
  .tabletitle {
    text-align: center !important;
  }
  .expensesearch {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-section {
    width: 100%;
    margin-bottom: 15px;
  }
  .close-btn {
    margin-right: 20px;
  }
  .vendor-fields1 {
    display: grid !important;
  }
  .expensesearch {
    width: 103% !important;
    gap: 100px !important;
  }
  .expenseaddbtn {
    display: flex;
    justify-content: end;
  }
  .expencetransport {
    margin-right: 0px !important;
  }
  .container {
    flex-direction: row;
    padding: 20px;
  }
  .exbtn {
    width: max-content !important;
  }
  .card-chart {
    flex: 60%;
  }
  .expensetabelheader {
    width: 100% !important;
    display: grid !important;
  }
  .expencebtn-excel {
    width: 50% !important;
  }
  .card-chart2 {
    flex: 40%;
  }

  .leads-icon {
    width: 40px;
  }
  .excelbtn {
    width: auto;
  }
  .search-field1 {
    width: 60%;
  }
  .datesearch {
    width: 100%;
    margin: 0px;
  }
  .datesearchinput {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .card-section {
    width: 100%;
    margin-bottom: 15px;
  }
  .rs-pagination-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rs-pagination,
  .rs-pagination-group-skip {
    margin-top: 10px;
  }

  .card-container {
    padding: 14px;
  }

  .container-table {
    margin: 14px;
  }

  .table-header {
    display: flex;
    justify-content: space-around;

    padding: 10px !important;
  }
  .excelbtn {
    width: 50%;
    margin-top: 10px;
  }
  .datepicker {
    width: 100%;
  }

  .container-table p {
    font-size: 20px;
    font-weight: 500;
    line-height: 30.01px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    width: 100%;
    margin-bottom: 0px;
  }
  .btn.excelbtn {
    width: 100%;
    margin-bottom: 10px;
  }
  .datepicker {
    width: 100%;
  }

  .table-search {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .search-field1 {
    width: 60%;
  }

  .dropdown-container {
    margin: 10px;
    height: 40px;
    width: 40%;
  }

  th {
    text-wrap: nowrap;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1440px;
    margin: 0px !important;
  }
}
@media (min-width: 1000px) {
  .convert-btn {
    width: 160px !important;
    margin-left: 10px !important;
  }
  .vendor-btn {
    width: 130px !important;
  }
  .backbtn {
    height: 37px !important;
  }
}
