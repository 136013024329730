.title {
  padding: 12px 25px !important;
  letter-spacing: 0.035em;
  pointer-events: none;
  cursor: default;
  font-size: 14px;
  color: #636363;
}

.content {
  color: #6c7780;
  display: block;
  padding: 15px 25px;
  margin: 3px 0;
  font-size: 15px;
  cursor: pointer;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}

.sidebar {
  width: 275px;
  background-color: rgba(245, 245, 245, 1);
}

.sidebar.open {
  width: 250px; /* Width when open */
}

.sidebar.closed {
  width: 80px; /* Width when closed */
}
.logo-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35.46px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-left: -27px;
}
li {
  padding: 12px 20px;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.sidebar-title img {
  width: 12px;
  height: 12px;
}

li span {
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 19.1px;
  letter-spacing: 0.4px;
  margin-left: 4%;
}

a {
  text-decoration: none;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  display: block;
}

.menu-button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 5%;
}

.submenu {
  list-style-type: none;
  padding-left: 20px;
  margin-top: 10px;
}

.sub-active {
  color: #77d7c2;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 4%;
}

.sidebar-toggle {
  width: 20px;
  height: 26px;
  margin-right: 1px;
  z-index: 1000;
  margin-top: 0px;
}

.sidebar-toggle {
  display: none !important;
}
@media screen and (max-width: 650px) {
  li {
    padding: 12px 10px;
  }

  .sidebar.closed {
    width: 60px;
  }
}
.sidebar {
  transition: transform 0.3s ease;
}

.sidebar.hidden {
  transform: translateX(-100%);
  transition: 0.2s ease !important;
}

.sidebar.open {
  transform: translateX(0);
  transition: 0.2s ease !important;
}

.sidebar-toggle-button {
  display: none;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80% !important; /* Adjust width as needed */
    transform: translateX(-150%);
    right: 100px !important;
    z-index: 6;
    transition: 0.2s;
    display: none;
  }
  .logo-title {
    font-size: 19.5px;
  }
  .sidebar.open {
    transform: translateX(0);
    display: block;
  }

  .mob-closed {
    display: none !important;
  }

  .mob-open {
    display: block !important;
  }

  .sidebar-toggle-button {
    display: inline-block;
    cursor: pointer;
  }
  .sidebar-toggle {
    display: block !important;
  }
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4%;
  }
}
