.container-table-user {
  box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  margin: 10px 10px 10px 10px;
  position: relative;
}

.table {
  --bs-table-border-color: #fff;
  --bs-table-bg: rgb(251, 255, 255);
}

.table th,
td {
  text-align: center;
}

.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
}

thead tr {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.82px;
  letter-spacing: 0.4px;
  color: rgb(51, 51, 51);
  background-color: #dedede;
}

.table td {
  font-size: 16px;
  font-weight: 500;
  line-height: 24.55px;
  letter-spacing: 0.4px;
  color: rgba(96, 96, 96, 1);
}

.table-header-user {
  padding: 20px 40px;
}

.table-container-user {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: rgb(230, 251, 253, 1);
  --bs-table-bg-type: #fff;
}

.container-table p {
  font-size: 22px;
  font-weight: 600;
  line-height: 30.01px;
  letter-spacing: 0.4px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  width: 22%;
  margin-bottom: 0px;
}

.table-header-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  margin: 10px;
  height: 40px;
  width: 15%;
}

select {
  border: none;
  background-color: rgba(249, 251, 255, 1);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  letter-spacing: 0.4px;
  color: rgba(126, 126, 126, 1);
}

.search-field1 {
  width: 25%;
  margin-right: 3%;
}

.table-search-user {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
}

.tabelselect {
  width: 200px !important;
  font-size: 14px;
}
.addinguser {
  width: 12%;
}
.adduserbtn {
  background-color: #00a7f6 !important;
  font-weight: 700;
  width: 100%;
  color: #fff;
  border-radius: 5px;
}
.search-input {
  border: 1px solid #b4b4b4;
  outline: none;
}
.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.newuser {
  background: linear-gradient(180deg, #30c9ed, rgb(4,106,134)) !important;
  font-weight: 700;
  width: 100%;
  color: #fff;
  border-radius: 8px;

}
/* .newuser:hover{
  background: #ffffff !important;
  color: #00a7f6 !important;
  border-color: #00a7f6 !important;
} */

.pagination-and-info-user {
  display: flex;
  justify-content: space-between;
  margin: 0% 5% 5%;
}

.pagination span {
  padding: 0px !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d6d6d6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 3.5px;
  background-color: rgb(255, 255, 255);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider{
  height: 24px;
  width: 50px;
}

input:checked + .slider {
  background-color: #00a7f6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00a7f6;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.labeltittle {
  font-weight: 600 !important;
}

.modaltittle.rs-modal-title {
  text-align: center;
  font-weight: 700;
}

.form-control {
  border: 1px solid gray;
}
