.vendorlist-card {
  width: 98%;
  height: fit-content;
  place-content: start;
  padding: 1%;
  margin: 1%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}

.statementinvendor {
  display: flex !important;
  padding: 50% 0% 50% 0% !important;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 70px 0px;
}
.card-title {
  font-size: 14.06px;
  font-weight: 500;
  line-height: 19.18px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #050506eb;
}

.card-info {
  font-size: 24.6px;
  font-weight: 700;
  line-height: 33.56px;
  letter-spacing: 0.8px;
  text-align: left;
  color: rgba(32, 34, 36, 1);
  margin-bottom: 0px;
  margin-top: 4%;
}

.vendorfieldscard1 {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.vendorfieldscard1 p {
  flex: 0 1;
  margin: 0;
  margin-right: 5px;
  white-space: nowrap;
}

.vendorfieldscard1 span {
  white-space: nowrap;
}

.vendorlist-row {
  font-size: 14px;
  font-weight: 100;
}
.state-btn {
  margin-right: 75%;
}
.vendorlist-row-col {
  display: flex;
  flex-wrap: wrap;
}

.vendorlist-row-col .vendorfieldscard1 {
  flex: 1 1 30%;
  min-width: 200px;
}

.card-details {
  height: 50px;
}

.card-section {
  width: 23%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 35px;
  padding: 10px 12px 14px;
  height: auto;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}

.card-section h2 {
  margin-top: 0;
}

.table-info {
  padding: 0px 6% 14px;
}
.table-title {
  font-size: 20px;
  margin: 3% 0%;
}
.card-and-search-container {
  padding: 0% 6%;
}

.addpo {
  width: 10% !important;
}
.container-qt {
  border-radius: 8px;
}

.cardmodal-sub {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.cardmodal-sub button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: black;
}
.mark-completed {
  color: green;
  display: flex;
  justify-content: flex-end;
}

.cardmodal-sub button.active {
  color: #fff;
  background-color: #00a7f6;
  border-radius: 5px;
}
.rs-tabs {
  gap: 0px;
}
.statement {
  display: flex;
  justify-content: end;
}

.underline-qt:hover {
  text-decoration: underline;
  cursor: grab;
}

@media (max-width: 1200px) {
  .card-container {
    padding: 20px 40px 0;
  }
  .card-section {
    width: 30%;
  }
}
.iconbg {
  background: none !important;
}
@media (max-width: 992px) {
  .card-container {
    padding: 20px 20px 0;
  }
  .card-section {
    width: 45%;
  }
  .vendorlist-row {
    flex-direction: column;
    align-items: center;
  }
  .vendorlist-row-col {
    width: 100%;
    padding: 0;
  }
  .vendorfieldscard1 {
    justify-content: center;
  }
}
@media (min-width: 1000px) {
  .mark-btn {
    width: 200px !important;
    margin-right: 10px;
  }
  .convert-btn {
    width: 190px !important;
    margin-left: 10px !important;
    height: 38px !important;
  }
  .vendor-btn {
    width: 130px !important;
  }
}

@media (max-width: 768px) {
  .card-container {
    padding: 20px 10px 0;
  }
  .card-section {
    width: 100%;
  }
  .mark-completed {
    justify-content: center;
    margin: 2% 0 0;
  }
  .vendorfieldscard1 p {
    flex: 0 0 100px;
  }
  .value {
    margin-left: 5px;
  }
}

@media (max-width: 576px) {
  .vendorlist-card {
    padding: 10px;
  }
  .card-container {
    padding: 10px 5px 0;
  }
  .card-section {
    width: 100%;
  }
  .vendorlist-row {
    margin: 1% 5%;
  }
  .vendorlist-row-col {
    width: 100%;
    padding: 0;
  }
  .vendorfieldscard1 p {
    flex: 0 0 80px;
  }
  .value {
    margin-left: 3px;
  }
  .container-qt {
    overflow: scroll;
  }
  .state-btn {
    margin-right: 0px;
  }
  .mark-btn {
    width: auto;
  }
}

.new-vendorlist-card {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.new-vendorlist-row {
  margin-bottom: 15px;
}

.new-vendorlist-col {
  display: flex;
  flex-direction: column;
}

.vendor-fields {
  margin-bottom: 15px;
  display: "flow";
}

.vendor-fields1 {
  margin-bottom: 15px;
  display: flex;
}

.field-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.field-value {
  font-size: 14px;
}
.rs-nav-item {
  padding: 5px !important;
}

.addc-btn {
  height: 30px;
  width: 100px !important;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

/* General styles */
.vendor-fields1,
.vendor-fields {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.field-label {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
}

.field-value {
  flex: 1;
  white-space: normal;
}

/* Mobile view adjustments */
@media (max-width: 767px) {
  .new-vendorlist-row {
    display: flex;
    flex-direction: column;
  }
  .addpo {
    width: 100% !important;
  }

  .new-vendorlist-col {
    margin-bottom: 15px;
  }

  .vendor-fields1,
  .vendor-fields {
    flex-direction: column;
  }

  .field-label {
    margin-bottom: 5px;
  }
}

/* Desktop view adjustments */
@media (min-width: 768px) {
  .new-vendorlist-row {
    display: flex;
    flex-wrap: wrap;
  }

  .new-vendorlist-col {
    flex: 0 0 33.33%; /* 3 columns */
    max-width: 33.33%; /* 3 columns */
    box-sizing: border-box;
    padding: 0 15px;
  }
}
