.addlead {
  padding: 4%;

}

.lead-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5%;
}

.lead-title span {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1%;
  color: rgba(3, 2, 41, 1);
}

.addleadinput {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
  height: 52px;
  width: 80%;
  padding: 0px 4%;
}

textarea {
  height:100px;
  padding: 2% 4%;
}

.lead-form {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.lead-form div {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
}
