.container-qt {
  width: 98%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}

.add-category-btn,
.add-row-btn,
.remove-category-btn,
.remove-row-btn,
.submit-btn {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  background: linear-gradient(180deg, #30c9ed, rgb(0 120 153)) !important;
  color: white;
  border: none;
  border-radius: 4px;
}
.remove-category-btn {
  padding: 0;
  font-size: 14px !important;
}

.tabletrline {
  width: 100%;
}

.totaltd {
  padding: 8px; /* Adjust padding as needed */
}

.totaltd:first-child {
  width: 75%;
  text-align: center; /* Adjust text alignment as needed */
}

.totaltd:last-child {
  width: 25%;
  text-align: right; /* Adjust text alignment as needed */
}
.rec-btn {
  width: 200px !important;
  height: 36px;
}

.pdf-btn {
  height: 36px;
}

.category-section {
  margin-bottom: 20px;
}

.remove-category-btn {
  font-size: 18px;
}

.cat_remove {
  background: linear-gradient(180deg, #30c9ed, rgb(0 120 153)) !important;
  color: white;
  padding: 0px 10px;
  font-weight: 900;
}

.category-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #00a7f6; */
  background-color: #595959;
  color: white;
  padding: 20px;
  border-radius: 4px;
  height: 45px !important;
}

input[type="number"],
input[type="date"],
input[type="text"],
textarea {
  width: 100%;
  /* padding: 5px; */
  box-sizing: border-box;
}
input[type="date"] {
  padding: 5px;
}
.submit-btn {
  display: block;
  margin: 20px auto;
  font-size: 17px;
}

/* Table */
.custom-table-ban {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-ban th,
.custom-table-ban td {
  padding: 8px;
  text-align: center;
  /* Center align the content */
}

.custom-table-ban th {
  background-color: #f2f2f2;
}

.custom-table-ban tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.custom-table-ban tbody tr:hover {
  background-color: #ddd;
}

.table-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
}

.custom-table-ban {
  width: 100%;
}

.custom-table-ban th,
.custom-table-ban td {
  flex: 1;
}

.add-category-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.checkbox-editor .ck-editor__editable_inline {
  height: 200px; /* Set the desired height */
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  display: flex;
  align-items: center;
}

.add-category-btn {
  margin-left: 10px;
  /* Adjust spacing between input and button */
}

.add-category-section input {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
  height: 42px;
}

.add-category-section button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #00a7f6;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.5);
}

.add-category-section button:hover {
  background-color: #00a7f6;
}

.customer-details {
  /* border: 1px solid; */
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 0 30px 2px #0c0a0b21;
}

.customer-details input {
  /* width: 200px; */
  /* padding: 8px; */

  box-shadow: 0 0 5px 1px #0c0a0b40;
  border: none;
  height: 38px;
  border-radius: 8px;
  padding-left: 15px;
  outline: none;
}
.custom-ckeditor .ck-editor__editable {
  box-shadow: 0 0 5px 1px #0c0a0b40;
  border: none;
  height: 100px;
  border-radius: 8px;
  padding-left: 15px;
  outline: none;
}
.ck.ck-editor__editable_inline > :first-child,
.ck.ck-editor__editable_inline > :last-child {
  margin-top: 4px;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border) !important;
  border-radius: 5px;
}
.ckeditor-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ck-editor__editable {
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
}
.customer-details textarea {
  /* width: 200px; */
  padding: 15px;

  box-shadow: 0 0 5px 1px #0c0a0b40;
  border: none;
  height: 50px;
  border-radius: 15px;
  padding-left: 15px;
  outline: none;
}

.input-style {
  box-shadow: 0 0 5px 1px #0c0a0b40;
  border: none;
  height: 50px;
  border-radius: 15px;
  padding-left: 15px;
  outline: none;
}

.table-input-style {
  border: 1px solid gray;
  height: 35px;
  border-radius: 10px;
  outline: none;
}

.customer-details .row {
  display: flex;
  justify-content: space-around;
}

.quo-d-flex {
  display: flex !important;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .container-qt {
    padding: 10px;
  }

  .title {
    font-size: 1.2em;
    /* Adjust font size for smaller screens */
  }

  .add-category-section {
    flex-direction: column;
    align-items: stretch;
  }

  .left-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .right-section {
    width: 100%;
    margin-top: 10px;
    /* Add margin to separate the button */
    display: flex;
    justify-content: flex-end;
  }

  .add-category-section input {
    width: 100%;
    /* Full width input on smaller screens */
    margin-bottom: 10px;
  }

  .add-category-section button {
    width: 100%;
    /* Full width buttons on smaller screens */
    margin-bottom: 10px;
  }

  .add-category-btn {
    margin-left: 0;
    /* Remove left margin */
  }
}
.row {
  padding: 1%;
}
.subbtn {
  width: 55%;
  color: #ffffff;
  background-color: #00a7f6;
}
.flex {
  display: flex;
  justify-content: center;
}
.removebtn {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  color: #ffffff;
  background-color: #00a7f6;
  border: none;
  border-radius: 4px;
}
/* .head{
  background-color: rgba(245, 245, 245, 1);
    color: black;
    margin-bottom: 20px;
    margin-top: 10px;
} */
.head {
  background-color: #f8f9fa; /* Light background color */
  /* padding: 5px; */
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
  margin-top: 10px;
}

.head h5 {
  margin: 0;
  padding: 10px;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
}

.head h5:hover {
  color: #4d6ee3; /* Change text color on hover */
  background-color: #e9ecef; /* Change background color on hover */
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-input {
  margin-left: 10px;
}
.righticon {
  position: relative;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 15px;
}
.grandtotal {
  font-size: 16px;
  font-weight: 700;
}
.textcenter {
  text-align: center;
}
.eyeicon {
  position: relative;
  left: 1%;
}
.flexend {
  display: flex;
  justify-content: end;
}

@media (max-width: 576px) {
  .add-category-section {
    flex-direction: column;
    align-items: stretch;
  }

  .customer-details .row {
    flex-direction: column;
    align-items: stretch;
  }

  .title {
    font-size: 1em;
  }

  .add-category-section input,
  .add-category-section button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 1000px) {
  .pe-entry {
    display: flex;
    justify-content: center;
  }
  .convert-btn {
    width: 160px !important;
    margin-left: 10px !important;
  }
}
.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  display: none;
}
