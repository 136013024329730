vendorsitelist.css


/* .vendorlist-card{
    width: 100%;
    background-color: lightgray;
    height: fit-content;
    place-content: start;
}
.vendorlist-row{
    display:flex !important;
    justify-content:space-between !important;
    margin:"1% 10% 1% 10%" !important;
    place-content:"center"!important;
}
.vendorlist-row-col{
    padding: 0.5%;
}
.vendorfieldscard{
    display: flex !important;
    padding: 2% 0% 2% 0%;
}
.statementinvendor{
    display:flex !important;
    padding: 50% 0% 50% 0% !important;
} */

.vendorlist-card {
  width: 98% !important;
  height: fit-content;
  place-content: start;
  padding: 1%;
  margin: 1%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  background-color: rgb(216, 216, 216);
  border: none;
}
.vendorlist-row {
  display: flex !important;
  justify-content: space-between !important;
  line-height: 30px;
  font-size: small;
  font-weight: 700;
}
.vendorlist-row-col {
  padding: 0.5%;
  text-wrap: wrap !important;
  margin-left: 2%;
  word-wrap: break-word !important;
  /* text-wrap: wrap !important; */
}
.vendorfieldscard1 {
  display: flex !important;
  justify-content: space-between !important;
}
.vendorfieldscard1 p {
  flex: 0 1;
}

.vendorfieldscard1 span {
  flex: 1;
}
.statementbtn {
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  height: 50px;
  font-weight: 700;
  font-size: small;
}
@media (max-width: 992px) {
  .vendorlist-row {
    flex-direction: column;
    align-items: center;
  }
  .vendorlist-row-col {
    width: 100%;
    padding: 0;
  }
  .vendorfieldscard1 {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .vendorfieldscard1 p {
    flex: 0 0 100px;
  }
  .value {
    margin-left: 5px;
  }
}

@media (max-width: 576px) {
  .vendorlist-card {
    padding: 10px;
  }
  .vendorlist-row {
    margin: 1% 5%;
  }
  .vendorlist-row-col {
    width: 100%;
    padding: 0;
  }
  .vendorfieldscard1 p {
    flex: 0 0 80px;
  }
  .value {
    margin-left: 3px;
  }
}
