body {
  margin: 0;
  background-color: #f0f0f0;
}

.container {
  background-color: #fff;
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.quotationcard {
  padding: 5%;
}

.mr-10 {
  margin-right: 10px;
}

.col-flex11 {
  display: flex;
  justify-content: space-between;
}

.header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 100px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.title-qt {
  flex: 2 1;
  padding: 25px 10px;
  color: black;
  background-color: #dadada;
}

.title-qt h3 {
  font-size: 23px;
  margin: 0;
  line-height: 42px;
}

.quotation-text {
  flex: 1;
  padding: 29px 10px;
  color: #fff;
  background-color: rgb(89, 89, 89);
  text-align: center;
}

.header1 .contact-info {
  text-align: right;
}

.header1 .contact-info p,
.header1 .contact-info a {
  margin: 0;
  color: white; /* Adjusted color */
}

.section-title {
  background-color: rgb(0, 167, 246);
  color: white;
  padding: 8px;
}

.quote-info {
  display: flex;
  flex-wrap: nowrap;
}

.quote-info > div {
  flex: 1 1 calc((100% / 3) - 10px);
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden; /* Hide overflow content */
}
.quotation-text h4 {
  font-size: 21px;
}

.quote-info p {
  margin: 0; /* Remove default margin */
  font-size: 1rem; /* Responsive font size */
  word-wrap: break-word; /* Ensure text wraps properly */
}

.table1 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table1 th,
.table1 td {
  border: none;
  padding: 8px;
}

.table1 th {
  background-color: #00a4e4;
  color: white;
}

.table1 td {
  vertical-align: middle;
}
.totaltd:first-child {
  width: 70%;
  text-align: center; /* Adjust text alignment as needed */
}

.totaltd:last-child {
  width: 30%;
  text-align: right; /* Adjust text alignment as needed */
}

.total {
  text-align: end;
  font-weight: bold;
}

.projectdiv {
  margin-top: 10px;
}

.quotespace {
  margin-left: 30px;
}

.datespace {
  margin-left: 50px;
}

.roomsection {
  padding-top: 15px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  text-align: center;
}

.underline {
  text-decoration: underline;
}
.convert-btn {
  width: 161px;
}

.listyle {
  font-size: 14px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-weight: 600;
}

.totaltd {
  color: #ffffff !important;
  font-size: 20px !important;
  padding: 5px;
  text-align: end;
}

.tabletrline {
  border: 1px solid #ffffff !important;
}
@media (max-width: 1024px) {
  .col-flex11 {
    flex-direction: row; /* Ensure row direction */
    align-items: flex-start;
  }

  .container {
    width: 95%;
    margin: 0 auto;
    box-shadow: none;
    padding: 10px;
  }
  .quotation-text h4 {
    font-size: 20px;
    line-height: 24px;
  }
  .datespace {
    margin-left: 0px;
  }

  .quotationcard {
    padding: 3%;
  }

  .title-qt,
  .quotation-text {
    padding: 15px;
  }
  .title-qt h3 {
    font-size: 20px;
    line-height: 48px;
  }

  .quote-info {
    flex-direction: row;
  }
  .quotespace {
    margin-left: 0px;
  }

  .quote-info > div {
    flex: 1 1 calc((100% / 3) - 10px);
  }

  .quote-info p {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }

  .table1 {
    margin-bottom: 10px;
  }

  .total {
    text-align: center;
  }

  .mr-10 {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .pdf-btn {
    width: 130px;
  }
  .col-flex11 {
    flex-direction: row; /* Ensure row direction */
    align-items: flex-start;
  }

  .container {
    width: 95%;
    margin: 0 auto;
    box-shadow: none;
    padding: 10px;
  }
  .datespace {
    margin-left: 0px;
  }

  .quotationcard {
    padding: 3%;
  }

  .title-qt,
  .quotation-text {
    padding: 15px;
  }
  .title-qt h3 {
    font-size: 20px;
    line-height: 48px;
  }

  .quote-info {
    flex-direction: row;
  }
  .quotespace {
    margin-left: 0px;
  }

  .quote-info > div {
    flex: 1 1 calc((100% / 3) - 10px);
  }

  .quote-info p {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }

  .table1 {
    margin-bottom: 10px;
  }

  .total {
    text-align: center;
  }

  .mr-10 {
    margin-right: 0;
  }
  .quotation-text h4 {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (max-width: 555px) {
  .header1 {
    flex-direction: row; /* Ensure row direction */
    align-items: flex-start;
  }

  .header1 .contact-info {
    text-align: left;
  }

  .col-flex11 {
    flex-direction: row; /* Ensure row direction */
    align-items: flex-start;
  }
  .quotespace {
    margin-left: 0px;
  }

  .quote-info {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .quote-info > div {
    flex: 1 1 calc((100% / 3) - 10px);
  }

  .quote-info p {
    font-size: 0.75rem; /* Adjust font size for smaller screens */
  }

  .title-qt,
  .quotation-text {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  .title-qt h3 {
    font-size: 19px;
    line-height: 55px;
  }

  .quotation-text {
    padding-top: 23px;
  }
  .quotation-text h4 {
    font-size: 15px;
    line-height: 47px;
  }
  .table1 th,
  .table1 td {
    padding: 5px;
  }

  .totaltd:first-child {
    width: 65%;
    text-align: center; /* Adjust text alignment as needed */
  }

  .totaltd:last-child {
    width: 45%;
    text-align: right; /* Adjust text alignment as needed */
  }

  .total {
    width: 100%;
    text-align: center;
  }
  .datespace {
    margin-left: 15px;
  }
}
@media (max-width: 490px) {
  .container {
    width: 100%;
    padding: 5px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .quotationcard {
    padding: 2%;
  }

  .title-qt,
  .quotation-text {
    padding: 10px;
    font-size: 0.875rem;
  }

  .quote-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
  }

  .quote-info > div {
    flex: 1 1 calc((100% / 3) - 10px);
  }
  .quote-info p {
    font-size: 0.75rem;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
  }

  .table1 {
    width: 100%;
    margin-bottom: 10px;
    border-collapse: collapse;
  }

  .table1 th,
  .table1 td {
    padding: 5px;
    font-size: 0.75rem; /* Adjust font size for small screens */
  }

  .total {
    font-size: 0.875rem;
    text-align: center;
  }

  .mr-10 {
    margin-right: 0; /* Remove margin */
  }

  .quotespace {
    margin-left: 0;
  }

  .datespace {
    margin-left: 0;
  }

  .title-qt h3 {
    font-size: 15px;
    line-height: 52px;
  }

  .quotation-text {
    padding-top: 15px;
  }

  .quotation-text h4 {
    font-size: 12px;
  }

  .header1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .header1 .contact-info {
    text-align: left;
  }
}
@media (max-width: 390px) {
  .container {
    width: 100%;
    padding: 5px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .quotationcard {
    padding: 2%;
  }

  .title-qt,
  .quotation-text {
    padding: 10px;
    font-size: 0.875rem;
  }

  .quote-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
  }

  .quote-info > div {
    flex: 1 1 calc((100% / 3) - 10px);
  }
  .quote-info p {
    font-size: 8px;
    word-wrap: break-word; /* Ensure text wraps properly */
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Break long words */
    white-space: normal;
  }

  .table1 {
    width: 100%;
    margin-bottom: 10px;
    border-collapse: collapse;
  }

  .table1 th,
  .table1 td {
    padding: 5px;
    font-size: 0.75rem; /* Adjust font size for small screens */
  }

  .total {
    font-size: 0.875rem;
    text-align: center;
  }

  .mr-10 {
    margin-right: 0; /* Remove margin */
  }

  .quotespace {
    margin-left: 0;
  }

  .datespace {
    margin-left: 0;
  }

  .title-qt h3 {
    font-size: 11px;
    line-height: 26px;
  }

  .quotation-text {
    padding-top: 15px;
  }

  .quotation-text h4 {
    font-size: 13px;
    line-height: 47px;
  }
  .header1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .header1 .contact-info {
    text-align: left;
  }
}
@media (max-width: 367px) {
  .container {
    width: 100%;
    padding: 5px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .quotationcard {
    padding: 2%;
  }

  .title-qt,
  .quotation-text {
    padding: 10px;
    font-size: 0.875rem;
  }

  .quote-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
  }

  .quote-info > div {
    flex: 1 1 calc((100% / 3) - 10px);
  }
  .quote-info p {
    font-size: 8px;
    word-wrap: break-word; /* Ensure text wraps properly */
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Break long words */
    white-space: normal;
  }

  .table1 {
    width: 100%;
    margin-bottom: 10px;
    border-collapse: collapse;
  }

  .table1 th,
  .table1 td {
    padding: 5px;
    font-size: 0.75rem; /* Adjust font size for small screens */
  }

  .total {
    font-size: 0.875rem;
    text-align: center;
  }

  .mr-10 {
    margin-right: 0; /* Remove margin */
  }

  .quotespace {
    margin-left: 0;
  }

  .datespace {
    margin-left: 0;
  }

  .title-qt h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .quotation-text {
    padding-top: 15px;
  }

  .quotation-text h4 {
    font-size: 13px;
    line-height: 47px;
  }
  .header1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .header1 .contact-info {
    text-align: left;
  }
}
